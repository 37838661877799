// @flow
import * as React from 'react';
import find from 'lodash/find';

import type { PrismicNewsEntry, PrismicUseCase } from '~schema';
import getEntrySummary, { type Options } from '~helpers/getEntrySummary';

const defaultOptions: Options = {
  maxSentencesCount: 2,
  maxWordsCount: 15,
};

export default function useEntrySummary(
  entry: ?PrismicNewsEntry | ?PrismicUseCase,
  options?: Options = defaultOptions,
) {
  const { maxSentencesCount, maxWordsCount } = options;

  const textSlice = React.useMemo(
    () => find(entry?.data?.body, { slice_type: 'text' }),
    [entry?.data?.body],
  );

  return React.useMemo(
    () =>
      getEntrySummary(
        // $FlowFixMe --> Reason: primary is missing in node
        textSlice?.primary?.slice_text?.text,
        { maxSentencesCount, maxWordsCount },
      ),
    [textSlice, maxSentencesCount, maxWordsCount],
  );
}
