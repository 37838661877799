// @flow
import flow from 'lodash/fp/flow';
import take from 'lodash/fp/take';
import trim from 'lodash/fp/trim';
import split from 'lodash/fp/split';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';

export type Options = {|
  maxSentencesCount?: number,
  maxWordsCount?: number,
|};

export const curryLimitSentences = (count: number) => (text: string) =>
  flow(trim, split(/\.\s/gm), take(count), map(trim), join('. '))(text);

export const curryLimitWords = (count: number) => (text: string) =>
  flow(
    take(count),
    join(' '),
  )((text.length && !text.endsWith('.') ? `${text}.` : text).split(' '));

export const curryApplyEllipsis = (originalText: ?string) => (text: string) =>
  originalText && text.length < originalText.length && !text.endsWith('.')
    ? text && `${text}...`
    : text;

export default function getEntrySummary(
  originalText: ?string,
  { maxSentencesCount = 1, maxWordsCount = 30 }: Options = {},
) {
  return flow(
    curryLimitSentences(maxSentencesCount),
    curryLimitWords(maxWordsCount),
    curryApplyEllipsis(originalText),
  )(originalText || '');
}
