/* eslint-disable no-use-before-define */
// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import RichText from '~plugins/prismic/components/RichText';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import BodySection from '~plugins/material-ui/components/BodySection';
import NodeLink from '~plugins/prismic/components/NodeLink';
import StyledCard from '~components/StyledCard';
import newDate from '~helpers/newDate';
import useArchiveFilters from '~hooks/useArchiveFilters';

import type { PrismicNewsEntryArchive } from '~schema';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'textWrapper'
  | 'title'
  | 'subtitle'
  | 'itemGridContainer'
  | 'itemGridItem'
  | 'entry'
  | 'entryWrapper';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicNewsEntryArchive>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
  },
  wrapper: {},
  textWrapper: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
  },
  title: {
    color: theme.palette.common.black,
  },
  subtitle: {
    '* + &': {
      marginTop: theme.spacing(3),
    },
  },
  itemGridContainer: {
    '* + &': {
      marginTop: theme.spacing(7),
    },
  },
  itemGridItem: {},
  entry: {
    width: '100%',
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 4px -1px rgba(0,0,0,0.1), 0px 6px 8px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
  entryWrapper: {
    height: '100%',
  },
});

const NewsEntryArchivePage = ({ classes, data }: Props) => {
  const intl = useIntl();
  const sortedNewsEntries = sortBy(data?.allPrismicNewsEntry?.nodes, [
    entry => entry?.data?.publication_date || entry?.first_publication_date,
  ]).reverse();

  const [filter, filteredEntries] = useArchiveFilters(sortedNewsEntries);

  return (
    <div className={classes.root}>
      <BodySection>
        <div className={classes.textWrapper}>
          <RichText
            {...data?.prismicNewsEntryArchive?.data?.title}
            className={classes.title}
          />
          <RichText
            {...data?.prismicNewsEntryArchive?.data?.subtitle}
            className={classes.subtitle}
          />
        </div>
        {filter}
        <Grid
          container
          spacing={4}
          alignItems="stretch"
          className={classes.itemGridContainer}
        >
          {map(filteredEntries, (entry, index: number) => {
            const publication_date = newDate(
              entry?.data?.publication_date || entry?.first_publication_date,
            );
            const options = { day: 'numeric', month: 'long', year: 'numeric' };

            return entry ? (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                className={classes.itemGridItem}
              >
                <StyledCard
                  node={entry}
                  title={entry?.data?.title}
                  image={entry?.data?.background_image}
                  publicationDate={intl.formatDate(publication_date, options)}
                  cardActionAreaProps={{
                    component: NodeLink,
                    node: entry,
                  }}
                  className={classes.entry}
                  classes={{
                    wrapper: classes.entryWrapper,
                  }}
                />
              </Grid>
            ) : null;
          })}
        </Grid>
      </BodySection>
    </div>
  );
};

export default compose(
  withNodePage<PrismicNewsEntryArchive, *>({
    getNode: data => data?.prismicNewsEntryArchive,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'NewsEntryArchivePage' }),
)(NewsEntryArchivePage);

export const query = graphql`
  query NewsEntryArchiveQuery($prismicId: ID, $prismicLocaleId: String) {
    prismicNewsEntryArchive(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
      }
    }
    allPrismicNewsEntry(filter: { lang: { eq: $prismicLocaleId } }) {
      nodes {
        id
        lang
        type
        href
        first_publication_date
        uid
        data {
          title {
            text
            html
          }
          publication_date
          tags {
            tag {
              id
              uid
              document {
                ... on PrismicEntryTag {
                  id
                  uid
                  data {
                    name {
                      text
                      html
                    }
                  }
                }
              }
            }
          }
          background_image {
            alt
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
          body {
            ... on Node {
              id
            }
            ... on PrismicNewsEntryBodyText {
              slice_type
              primary {
                slice_text {
                  text
                  html
                }
              }
            }
          }
        }
        parent {
          ... on PrismicNewsEntryArchive {
            id
            type
            prismicId
          }
        }
      }
    }
  }
`;
