/* eslint-disable no-nested-ternary */
// @flow
import * as React from 'react';

import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import NodeImage from '~plugins/prismic/components/NodeImage';
import useEntrySummary from '~hooks/useEntrySummary';
import type {
  PrismicNewsEntry,
  PrismicPressEntry,
  PrismicUseCase,
  PrismicStructuredTextType,
  PrismicImageType,
} from '~schema';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'imageWrapper'
  | 'image'
  | 'cardContent'
  | 'priceWrapper'
  | 'price'
  | 'title'
  | 'description'
  | 'summary'
  | 'publicationDate'
  | 'useCaseLogo'
  | 'useCaseLogoImage';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  node?: ?PrismicNewsEntry | ?PrismicPressEntry | ?PrismicUseCase,
  title?: ?PrismicStructuredTextType,
  description?: ?PrismicStructuredTextType,
  image?: ?PrismicImageType,
  useCaseLogo?: ?PrismicImageType,
  price?: ?string,
  linkLabel?: ?string,
  publicationDate?: ?string,
  cardActionAreaProps?: ?$Shape<React.ElementConfig<typeof CardActionArea>>,
  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  wrapper: {
    '& .MuiCardActionArea-root': {
      height: '100%',
    },
    '& .MuiCardActionArea-focusHighlight': {
      backgroundColor: 'transparent',
      opacity: 0,
    },
  },
  imageWrapper: {
    background: 'linear-gradient(180deg, #E0E0E0 0%, #CECECE 100%)',
    position: 'relative',
  },
  image: {
    height: 260,
    [theme.breakpoints.up('sm')]: {
      height: 220,
    },
    '& img': {
      transition: 'all .3s !important',
      '-webkit-transition': 'all .3s !important',
    },
    '$wrapper:hover &': {
      '& img': {
        transform: 'scale(1.05)',
      },
    },
  },
  cardContent: {
    textAlign: 'left',
  },
  priceWrapper: {
    display: 'inline-block',
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
  },
  price: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.common.black,
    '$wrapper:hover &': {
      color: theme.palette.primary.main,
    },
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(1),
    },
  },
  summary: {
    '* + &': {
      marginTop: theme.spacing(1),
    },
  },
  publicationDate: {
    marginBottom: theme.spacing(1),
  },
  useCaseLogo: {
    position: 'absolute !important',
    width: '100%',
    height: 'auto',
    maxWidth: 85,
    minWidth: 85,
    minHeight: 85,
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 3px 4px -1px rgba(0,0,0,0.1), 0px 6px 8px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
  },
  useCaseLogoImage: {
    position: 'absolute !important',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: theme.spacing(1),
    '& img': {
      objectFit: 'contain !important',
    },
  },
});

const StyledCard = ({
  node,
  title,
  description,
  image,
  price,
  linkLabel,
  publicationDate,
  useCaseLogo,
  className,
  classes,
  cardActionAreaProps,
  ...props
}: Props): React.Node => {
  const defaultSummary = useEntrySummary(node);
  return (
    <Card
      square
      variant="outlined"
      className={classnames(classes.root, className)}
      {...props}
    >
      <CardActionArea
        {...cardActionAreaProps}
        arial-label={linkLabel || title?.text}
        className={classes.wrapper}
      >
        <div>
          <div className={classes.imageWrapper}>
            <NodeImage data={image} className={classes.image} />
            {useCaseLogo ? (
              <div className={classes.useCaseLogo}>
                <NodeImage
                  data={useCaseLogo}
                  className={classes.useCaseLogoImage}
                />
              </div>
            ) : null}
          </div>
          <CardContent className={classes.cardContent}>
            {price ? (
              <div className={classes.priceWrapper}>
                <Typography variant="h6" className={classes.price}>
                  {price}
                </Typography>
              </div>
            ) : null}
            {publicationDate ? (
              <Typography
                component="p"
                variant="overline"
                className={classes.publicationDate}
              >
                {publicationDate}
              </Typography>
            ) : null}
            <Typography variant="h5" className={classes.title}>
              {title?.text}
            </Typography>
            {node ? (
              <RichTextTypography
                component="p"
                html={defaultSummary}
                className={classes.summary}
              />
            ) : description ? (
              <RichText {...description} className={classes.description} />
            ) : null}
          </CardContent>
        </div>
      </CardActionArea>
    </Card>
  );
};

StyledCard.defaultProps = {
  className: undefined,
  node: undefined,
  title: undefined,
  description: undefined,
  image: undefined,
  price: undefined,
  linkLabel: undefined,
  publicationDate: undefined,
  useCaseLogo: undefined,
  cardActionAreaProps: undefined,
};

export default withStyles<*, *, *>(styles, { name: 'StyledCard' })(StyledCard);
