// @flow
import * as React from 'react';
import Typography from '@material-ui/core/Typography';

import RichText from '../RichText';

export type Props = {
  ...$Exact<React.ElementConfig<typeof Typography>>,
  ...$Exact<$Diff<React.ElementConfig<typeof RichText>, { Component: any }>>,
};

/*
This regular expression will remove all MuiTypography classes already set by
the `htmlSerializer`. This is done because this html will be rendered inside
a new `Typography` component, and the children styles will prevail on the
parent ones.
*/
const regExp = /MuiTypography(-|[a-z]|[1-9])+/gi;

/**
 * Takes a rich text field and renders it inside a Typography. It will strip all
 * typography classes inside the provided html string.
 * 
 * Using {@link RichText} by default is prefered, but since it is kind of rigid
 * (because it is limited to the tags provided by Prismic), in some cases it may
 * be useful to use `RichTextTypography`, since it will let to override the
 * already serialized typography classes.
 * 
 * Please note that weird styles can appear, as only the provided variant will be
 * set, but the html provided may contain any of the available tags in Prismic.
 * This can lead to weird combinations (ex: variant set to `caption` rendering
 * `h1`).
 */
const RichTextTypography = ({ html, ...props }: Props): React.Node => {
  return (
    <RichText
      // FIXME: spreading component prop to avoid flow error with Typography
      {...{ component: 'div' }}
      {...props}
      html={React.useMemo(
        () => html && html.replace(regExp, ''),
        [html],
      )}
      Component={Typography}
    />
  );
};

RichTextTypography.defaultProps = {
  className: undefined,
};

export default RichTextTypography;
